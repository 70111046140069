.logo {
    margin-left: 7px;
    margin-right:20px;
    height: 60px;
    width: 110px;
}

.navbar {
    padding: 10px 20px 10px 20px;
    border-bottom: 1px solid #fff;
}

.navbar a {
    color: #173C7C;
    text-decoration: none;
    font-weight: bold;
    font-size: 20px;
}