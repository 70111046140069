
.bars {
   width: 60px;
   height: 50px;
   --c: repeating-linear-gradient(90deg,#f03a5f 0 10px, #0000 0 20px);
   background: var(--c) 50% 0,
          var(--c) 50% 100%;
   background-size: 50px 50%;
   background-repeat: no-repeat;
   animation: bars-obuq80 1s infinite linear;
}

@keyframes bars-obuq80 {
   33% {
      background-position: 0 0, 100% 100%;
   }

   66% {
      background-position: 0 100%, 100% 0;
   }

   100% {
      background-position: 50% 100%,50% 0;
   }
}

.box-loader {
    width: 100%;
    height: 100%;
    display: grid;
    /*grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));*/
    grid-template-rows: repeat(auto-fit, minmax(100px, 1fr));
    grid-gap: 10px;
    justify-items: center;
    align-items: center;
    font-family: Lato, sans-serif;
    font-size: 1.3rem;
    font-weight: bold;
}
